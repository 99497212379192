import Introduction from './components/Introduction'
import './App.css';
import TopBar from './components/TopBar';
import Interests from './components/Interests';
import { useRef } from 'react';
import { Element } from 'react-scroll'


function App() {
  var interestsRef = useRef<HTMLDivElement>(null);

  return (
    <>
    {/* <TopBar /> */}
    <Introduction />

    {/* <Element
      name={'interests'}
      >
      <Interests ref={interestsRef} />
      
    </Element> */}
    </>
  );
}

export default App;
